import React from "react"
import NavigationBar from "../components/Navbar"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

export default class ContactFormPage extends React.Component {
  state = {
    selectedOption: null,
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    )
  }
  render() {
    const { selectedOption } = this.state
    const options = [
      { value: "subjectlearningguidance", label: "Subject learning guidance" },
      { value: "careerguidance", label: "Career guidance" },
      { value: "platformrelatedfeedback", label: "Platform related feedback" },
      { value: "otherqueries", label: "Other Queries" },
    ]
    return (
      <div>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Contact Us</title>
          <meta name="description" content="Contact Us Page" />
        </Helmet>
        <NavigationBar />
        <div className="relative bg-white">
          <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
            <div className="px-4 pt-10 pb-0 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="tracking-wider text-2xl lg:text-3xl 2xl:text-4xl text-cool-gray-900 leading-9">
                  Get in Touch
                </h2>
                <ul className="mt-3 leading-6 text-base text-left text-gray-500 lg:text-lg xl:text-xl list-outside">
                  Drop a line, or give us a heads up if you are interested in
                  visiting us. Our expert team will answer your queries. In the
                  meantime, we would love to hear your critical ideas regarding:
                  <li className="mt-4 ml-5 text-base text-left text-gray-500 lg:text-lg xl:text-xl list-disc">
                    EnjoyMathematics platform
                  </li>
                  <li className="ml-5 text-base text-left text-gray-500 lg:text-lg xl:text-xl list-disc">
                    Creative content design
                  </li>
                  <li className="ml-5 text-base text-left text-gray-500 lg:text-lg xl:text-xl list-disc">
                    Joining our team
                  </li>
                  <li className="ml-5 text-base text-left text-gray-500 lg:text-lg xl:text-xl list-disc">
                    Problem solving guidance
                  </li>
                </ul>
                <dl className="mt-8 text-base leading-6 text-gray-500">
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 w-6 h-6 text-green-500"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3 text-base text-left text-gray-500 lg:text-lg xl:text-xl">
                        contact@enjoyalgorithms.com
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="px-4 pt-10 pb-2 bg-white sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <form
                  name="contact"
                  method="POST"
                  action="/thank-you/"
                  data-netlify="true"
                  className="grid grid-cols-1 row-gap-6"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="my-2">
                    <label htmlFor="full_name" className="sr-only">
                      Full name
                    </label>
                    <div className="relative border-2 border-gray-300 rounded-md shadow-sm">
                      <input
                        name="fullname"
                        id="full_name"
                        className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                        placeholder="Full name"
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <div className="relative border-2 border-gray-300 rounded-md shadow-sm">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <div className="relative border-2 border-gray-300 rounded-md shadow-sm">
                      <input
                        name="phone"
                        id="phone"
                        className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                        placeholder="Phone"
                      />
                    </div>
                  </div>

                  <div className="my-2">
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <div className="relative border-2 border-gray-300 rounded-md shadow-sm">
                      <textarea
                        name="message"
                        id="message"
                        rows={4}
                        className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                  </div>

                  <div className="my-2">
                    <span className="inline-flex rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="tracking-wider inline-flex justify-center px-6 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-new-purple shadow rounded hover:text-gray-900 hover:bg-green-50 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700"
                      >
                        Submit
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-14">
          <Footer />
        </div>
      </div>
    )
  }
}
